import PropTypes from "prop-types";
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  DataTypeProvider,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableHeaderRow,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import {
  Box,
  Typography,
  Link,
  SvgIcon,
  Stack,
  Popper,
  Grow,
  Button,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  InputAdornment,
  CircularProgress,
  Tooltip,
  FormHelperText, Autocomplete
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReportIcon from "@material-ui/icons/Report";
import { Link as RouterLink } from '@reach/router';
import dayjs from "dayjs";
import { cloneDeep, findIndex, find } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { ReactSpoiler } from "react-simple-spoiler";
import { useDebouncedCallback } from "use-debounce";

import HelpTooltip from 'components/HelpTooltip';

import {
  COLUMN_EXTENSIONS_STRUCTURE_BILLS,
  COLUMNS_MODEL_CREATOR_BILLS,
  COLUMNS_MODEL_CREATOR_SHIPMENTS,
  COLUMN_EXTENSIONS_STRUCTURE_SHIPMENTS,
  COLUMNS_MODEL_CREATOR_SKU_BILLING,
  COLUMN_EXTENSIONS_STRUCTURE_SKU_BILLING,
} from "./constants";
import BillingModalReject from "./documentActionTypeProvider/BillingModalReject";
import InvoiceNumberPDF from "./documentActionTypeProvider/InvoiceNumberPDF";
import ShipmentsDocument from "./documentActionTypeProvider/ShipmentsDocument";
import ShipmentsModalActions from "./documentActionTypeProvider/ShipmentsModalActions";
import ShipmentsModalUploadFile from "./documentActionTypeProvider/ShipmentsModalUploadFile";
import { INVOICES_CREATE } from "./graphql/mutations/InvoicesCreate";
import { NOTE_CREATE } from "./graphql/mutations/noteCreate";
import { SHOP_ORDERS_ADD_GOOD } from "./graphql/mutations/shopOrdersAddGood";
import { SHOP_ORDERS_CHANGE_STATUS } from "./graphql/mutations/shopOrdersChangeStatus";
import { SHOP_ORDERS_REJECT } from "./graphql/mutations/shopOrdersReject";
import { SHOP_ORDERS_REMOVE_GOOD } from "./graphql/mutations/shopOrdersRemoveGood";
import { SHOP_ORDERS_UPDATE } from "./graphql/mutations/shopOrdersUpdate";
import { SHOP_SUPPLIES_CREATE } from "./graphql/mutations/shopSuppliesCreate";
import { GET_INVOICE_DEFAULT_VALUES } from "./graphql/queries/getInvoiceDefaultValues";
import { GET_SHOP_GOODS } from "./graphql/queries/getShopGoods";
import { GET_SHOP_ORDER } from "./graphql/queries/getShopOrder";
import { ReactComponent as ArrawDownIcon } from './img/arrawDown.svg';
import { ReactComponent as IconBasket } from './img/basket.svg';
import { ReactComponent as IconEdit } from './img/edit.svg';
import minRub from "./img/min-rub.svg";
import { ReactComponent as ReturnIcon } from './img/return.svg';
import telegramIcon from "./img/telegram.svg";
import AutocompleteEntities from "../../components/AutocompleteEntities";
import AutocompleteTaskUsers from "../../components/AutocompleteTaskUsers";
import AvatarUser from "../../components/AvatarUser";
import CurrencyFormatter from "../../components/CurrencyFormatter";
import { DatePickerBox } from "../../components/DatePickerBox";
import ErrorAlert from "../../components/ErrorAlert";
import Phone from "../../components/Phone";
import Tabs from "../../components/Tabs";
import WazzupLink from "../../components/WazzupLink";
import { DATE_TIME_FORMAT_NOT_Z } from "../../core/constants/Formats";
import { GridRoot } from "../deals/components/helpers";
import AzDropzone from "../workspaces/components/Dropzone/AzDropzone";

export const DocumentActionTypeProviderReject = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <BillingModalReject {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export const DocumentInvoiceNumberPDF = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <InvoiceNumberPDF {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export const DocumentActionTypeProviderShipmentsActions = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <ShipmentsModalActions {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export const DocumentActionTypeProviderShipmentsUploadFile = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <ShipmentsModalUploadFile {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export const DocumentActionTypeProviderShippingDocument = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <ShipmentsDocument {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

OrderDetails.propTypes = {
  id: PropTypes.string,
}

export default function OrderDetails({ id: idOrder }) {
  const stylesTabs = useMemo(() => ({
    wrapperTabs: {
      padding: 0,
      borderTop: '1px solid #E7E8EA',
      borderBottom: '1px solid #E7E8EA',

      '& .MuiTabs-flexContainer': {
        justifyContent: 'space-evenly',
      }
    },
    tab: {
      minWidth: 'auto !important',
      width: 'auto !important',
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 700,
      padding: '13px, 0',
    },
  }), []);
  const styles = useMemo(() => ({
    colorStatus: {
      color: '#ffffff',
      padding: '4px',
      borderRadius: '4px',
      fontSize: '13px',

      '&.primary_consultation, &.lead': {
        backgroundColor: '#c3c3c3'
      },

      '&.proposal_sent, &.proposal_familiarized': {
        backgroundColor: 'gray'
      },

      '&.preparing_documents': {
        backgroundColor: '#4adadf'
      },

      '&.leasing_sale': {
        backgroundColor: '#2ec7ce'
      },

      '&.verification': {
        backgroundColor: '#0f9fa6'
      },

      '&.additional_documents_request': {
        backgroundColor: '#2690bd'
      },

      '&.underwriting': {
        backgroundColor: '#cfc034'
      },

      '&.governanceing': {
        backgroundColor: '#bfb832'
      },

      '&.preapproved': {
        backgroundColor: '#8fa021'
      },

      '&.approved': {
        backgroundColor: '#76851d'
      },

      '&.preparing_deal': {
        backgroundColor: '#6d650f'
      },

      '&.deal': {
        backgroundColor: '#9adb58'
      },

      '&.advance_payment_received': {
        backgroundColor: '#7bae0e'
      },

      '&.partial_payment': {
        backgroundColor: '#6b8d23'
      },

      '&.issued': {
        backgroundColor: '#538f1d'
      },

      '&.extinguished': {
        backgroundColor: '#ff9220'
      },

      '&.trash': {
        backgroundColor: '#d12200'
      },
    },
    modal: {
      '& .MuiDialog-paper': {
        maxWidth: '698px',
        borderRadius: '16px',
      }
    },
    chekedInput: {
      '& .MuiButtonBase-root': {
        color: '#CFD2D6',
      },
      '& .Mui-checked': {
        color: '#3651DE !important',
      },
      '& .MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 500,
        color: '#0D1D32',
      }
    },
    select: {
      border: '1px solid #CFD2D6',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: 0,
      color: '#6E7884',
      maxHeight: '48px',

      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        border: '1px solid #0D1D32',
        background: '#FFFFFF',
      },
      '&:active': {
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',
      },
      '& > div': {
        padding: '12.5px 0 12.5px 14px',
        background: 'transparent !important',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    selectItem: {
      '&': {
        color: '#6E7884',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&:active': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff !important',
        color: '#3D6CE7 !important',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    autocomplete: {
      '& .MuiInputBase-root': {
        background: '#ffffff',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        borderRadius: '8px',
        fontWeight: '400',
        fontSize: '16px',

        '&::placeholder': {
          color: '#B6BBC2',
        },
        '&.Mui-focused fieldset': {
          border: '1px solid #3D6CE7 !important',
        },
        '&.Mui-disabled': {
          background: '#F5F6F7',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
      },
    },
    autocompleteList: {
      color: '#6E7884',
      display: 'flex',
      justifyContent: 'space-between !important',

      '&.MuiAutocomplete-option[aria-selected=true]': {
        backgroundColor: '#ffffff!important',
        color: '#3D6CE7 !important',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05) !important',
      },
      '&:active': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05) !important',
      },
    },
    blueButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    grayButton: {
      color: '#0C1C32',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#ECEDEF',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',
      border: '1px solid #ECEDEF',

      '&:hover': {
        background: '#F3F4F5',
        border: '1px solid #F3F4F5',
        color: '#3D6CE7',
        boxShadow: 'none',
      },
      '&:active': {
        background: '#E7E8EA',
        border: '1px solid #E7E8EA',
        color: '#3256B9',
      },
      '&:disabled': {
        background: '#CED2D6',
        border: '1px solid #CED2D6',
        color: '#868D98',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    pageTitle: {
      fontSize: '32px !important',
      margin: '0 !important',
      fontWeight: 700,
      display: 'flex',
      gap: '6px',
      alignItems: 'center',

      '& span': {
        color: "#3D6CE7",
      }
    },
    chipTitle: {
      fontSize: "14px",
      borderRadius: "4px",
      padding: "6px 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "8px",
      height: '28px',

      '&.sale': {
        color: '#24A1FF',
        background: '#E5F3FF',
      }
    },
    wrapperContent: {
      display: "flex",
      margin: '24px',
      borderRadius: '16px',
      // height: 'calc(100% - 120px)',
      overflow: 'hidden',
      gap: "24px",
    },
    contentLeft: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: 'column',
      gap: "24px",
      width: "64%",
    },
    contentRight: {
      display: "flex",
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      gap: "24px",
      width: "36%",
    },
    content: {
      background: "#ffffff",
      padding: "24px",
      boxShadow: "0px 2px 6px rgba(13, 29, 50, 0.1)",
      borderRadius: "16px",
      width: "100%",
    },
    greyOutlineButton: {
      fontWeight: 500,
      fontSize: '16px',
      color: '#6E7884',
      border: '1px solid #CFD2D6',
      borderRadius: '6px',
      display: 'inline-flex',
      padding: '11px 14px',
      cursor: 'pointer',
      userSelect: 'none',
      textTransform: 'none',
      maxHeight: '48px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },

      '& svg path': {
        fill: '#B6BBC2',
      },

      '&:hover': {
        background: 'rgba(61, 108, 231, 0.05)',
        border: '1px solid #3D6CE7',
        color: '#3D6CE7',
        textDecoration: 'none',

        '& svg path': {
          fill: '#3D6CE7',
        },
      },
      '&:active': {
        color: '#ffffff',
        background: '#3D6CE7',
        border: '1px solid #3D6CE7',

        '& svg path': {
          fill: '#ffffff',
        },
      },
      '&:disabled': {
        border: '1px solid #CFD2D6',
        color: '#CFD2D6',
        background: '#ffffff',
        cursor: 'default',

        '& svg path': {
          fill: '#B6BBC2',
        },
      },
    },
    blueOutlineButton: {
      fontWeight: 500,
      fontSize: '16px',
      color: '#3D6CE7',
      border: '1px solid #3D6CE7',
      borderRadius: '6px',
      display: 'inline-flex',
      alignItems: 'center',
      padding: '11px 15px',
      maxHeight: '56px',
      cursor: 'pointer',
      userSelect: 'none',
      textTransform: 'none',
      '& .MuiTouchRipple-root': {
        display: 'none',
      },

      '& svg path': {
        fill: '#3D6CE7',
      },

      '&:hover': {
        background: 'rgba(61, 108, 231, 0.05)',
        color: '#6489EC',
        border: '1px solid #6489EC',
        textDecoration: 'none',

        '& svg path': {
          fill: '#6489EC',
        },
      },
      '&:active': {
        background: 'rgba(61, 108, 231, 0.1)',
        color: '#3156B9',
        border: '1px solid #3156B9',

        '& svg path': {
          fill: '#3156B9',
        },
      },
      '&:disabled': {
        color: '#CFD2D6',
        border: '1px solid #CFD2D6',
        cursor: 'default',
        background: '#FFFFFF',

        '& svg path': {
          fill: '#CFD2D6',
        },
      },
    },
    returnOutlineButton: {
      border: '1px solid #CFD2D6',
      borderRadius: '8px',
      display: 'inline-flex',
      padding: '16px',
      marginTop: '5px',
      cursor: 'pointer',
      userSelect: 'none',

      '& svg path': {
        fill: '#6E7884',
      },

      '&:hover': {
        background: '#F5F8FE',
        border: '1px solid #3D6CE7',
        textDecoration: 'none',

        '& svg path': {
          fill: '#3D6CE7',
        },
      },
      '&:active': {
        background: '#3D6CE7',
        border: '1px solid #3D6CE7',

        '& svg path': {
          fill: '#ffffff',
        },
      },
      '&:disabled': {
        border: '1px solid #E7E8EA',
        background: '#E7E8EA',
        cursor: 'default',

        '& svg path': {
          fill: '#9EA5AD',
        },
      },
    },
    kitWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderTop: '1px solid #E7E8E9',
      padding: '19px 0',
    },
    kitHeadTitle: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      color: '#B6BBC2',
    },
    kitTitle: {
      color: '#0D1D32',
      fontSize: '16px',
      fontWeight: 700,
    },
    kitLink: {
      color: '#3D6CE7',
      fontSize: '16px',
      fontWeight: 700,
    },
    kitStatus: {
      padding: '4px',
      background: '#F1F8E3',
      fontSize: '14px',
      fontWeight: 500,
      color: '#67BF3D',
      borderRadius: '4px',
      lineHeight: '17px',
    },
    kitPrice: {
      fontSize: '20px',
      fontWeight: 700,
      color: '#0D1D32',
    },
    kitVat: {
      color: '#6E7884',
      fontSize: '14px',
      fontWeight: 300,
    },
    kitCount: {
      fontSize: '14px',
      fontWeight: 300,
      color: '#0D1D32',
    },
    kitButton: {
      borderRadius: '4px',
      background: '#F5F6F7',
      marginLeft: '8px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },

      '&:hover': {
        background: '#3D6CE7',

        '& path': {
          fill: '#ffffff',
        },
      },
      '&:disabled': {
        background: '#CFD2D6',

        '& path': {
          fill: '#ffffff',
        },
      },
    },
    kitTotalWrapper: {
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 300,
      borderTop: '1px solid #E7E8E9',
      paddingTop: '20px',
      textAlign: "right",
    },
    kitVal: {
      fontWeight: 300,
      fontSize: '16px',
      display: 'inline-block',
      color: '#0D1D32',
      textAlign: 'right',
    },
    kitTotalBold: {
      color: '#0D1D32',
      fontSize: '20px',
      fontWeight: 700,
      display: 'inline-block',
    },
    outlineRedButton: {
      border: '1px solid #FF544F',
      borderRadius: '4px',
      background: '#FFFFFF',
      marginRight: '8px',

      '& .MuiTouchRipple-root': {
        display: 'none'
      },

      '& path': {
        fill: '#FF544F',
      },

      '&:hover': {
        background: '#FFF6F6',
        border: '1px solid #FF7772',

        '& path': {
          fill: '#FF7773',
        },
      },

      '&:active': {
        background: '#FFEEED',
        border: '1px solid #D84745',

        '& path': {
          fill: '#D94843',
        },
      }
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '8px',
    },
    input: {
      maxHeight: '48px',

      '& > div': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
        zIndex: 1,

        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
      '& .Mui-disabled': {
        '& input': {
          color: '#6E7884',
          zIndex: 1,
        },
        '& fieldset': {
          border: '1px solid #CFD2D6 !important',
          background: '#F5F6F7',
        },
        '&:hover fieldset': {
          border: '1px solid #CFD2D6 !important',
        }
      },
    },
    radioInput: {
      '& .MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 500,
        color: '#0D1D32',
      },
      '& .MuiButtonBase-root': {
        color: '#CFD2D6',
      },
      '& .Mui-checked': {
        color: '#3651DE !important',
      },
    },
    inputDate: {
      marginTop: '1px',

      '& .MuiFormHelperText-root': {
        position: 'initial',
      },
      '& > div': {
        paddingRight: '0',
      },
      '& > button': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
    },
    buttonEdit: {
      position: 'absolute',
      top: '24px',
      right: 0,
      width: '36px',
      height: '36px',
      background: '#F5F6F7',
      borderRadius: '4px',
      padding: '10px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },

      '&:hover': {
        background: '#3D6CE7',

        '& path': {
          fill: '#ffffff',
        }
      }
    },
    wrapperTable: {
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      marginBottom: '24px',
      overflow: 'hidden',
      background: '#FFFFFF',
      minHeight: '120px',
      position: 'relative',

      '&.insaid': {
        border: 'none',
        borderRadius: '0',
        marginBottom: '0',

        '& .MuiTableBody-root tr:last-child td': {
          borderBottom: 'none',
        }
      },
      '& div': {
        height: 'auto !important',
      },
      '& .MuiTableHead-root tr': {
        backgroundColor: '#ffffff !important',

        '& span': {
          color: '#6E7884',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .Mui-checked': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
      },
      '& .CustomPaging': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button:hover': {
        backgroundColor: '#3D6CE7 !important',
        color: '#ffffff',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    },
    redButton: {
      background: '#FF5650',
      fontSize: '16px',
      fontWeight: 500,
      color: '#FFFFFF',
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        background: '#FF7773',
        boxShadow: '0px 6px 16px 0px rgba(229, 87, 87, 0.30)',
      },
      '&:active': {
        background: '#D94843',
      },
      '&:disabled': {
        background: '#CED2D6',
        color: '#868E98'
      },
    },
  }), []);

  const {
    clearErrors,
    control,
    errors,
    handleSubmit,
    setValue
  } = useForm({
    mode: "all"
  })

  const [order, setOrder] = useState({});
  const [loadingOrder, setLoadingOrder] = useState(true);

  const [totalAmountGoods, setTotalAmountGoods] = useState(0);
  const [totalVatGoods, setTotalVatGoods] = useState(0);
  const [totalAmountSupplies, setTotalAmountSupplies] = useState(0);

  const [messageError, setMessageError] = useState(null);

  const [getInvoiceValues, { data: dataInvoiceDefaultValues, error: errorInvoiceDefaultValues, loading: loadingInvoiceDefaultValues }] = useLazyQuery(GET_INVOICE_DEFAULT_VALUES, {
    fetchPolicy: 'network-only',
  });

  const { error: errorOrder  } = useQuery(GET_SHOP_ORDER, {
    variables: {
      orderId: idOrder
    },
    onCompleted: res => {
      setOrder(res.shopOrder);

      setLoadingOrder(false);
    },
    onError: () => {
      setLoadingOrder(false);
    }
  });

  useEffect(() => {
    if (order?.availableGoods?.length) {
      let sumAmountGoods = order.availableGoods.reduce((sum, good) => sum + good.priceWithDiscount, 0);
      setTotalAmountGoods(sumAmountGoods);

      let sumVatGoods = order.availableGoods.reduce((sum, good) => sum + good.vat, 0);
      setTotalVatGoods(sumVatGoods);
    }
  }, [order?.availableGoods]);

  useEffect(() => {
    if (order?.supplies?.length) {
      let sumAmountSupplies = order.supplies.filter(({status}) => status === "PENDING").reduce((sum, supplier) => sum + supplier.amount, 0);

      setTotalAmountSupplies(sumAmountSupplies);
    }
  }, [order?.supplies]);

  const [createNote, { loading: loadingCreateNote }] = useMutation(NOTE_CREATE, {
    onCompleted: res => {
      const cloneOrder = cloneDeep(order);
      cloneOrder.notes.push(res.noteCreate.note);
      setOrder(cloneOrder);

      onToggleModal("addNotes");
    },
    onError: error => {
      setMessageError(error)
      onToggleModal('error');
    }
  });

  const [orderToReject, { loading: loadingOrderToReject }] = useMutation(SHOP_ORDERS_REJECT, {
    onCompleted: res => {
      const cloneOrder = cloneDeep(order);

      cloneOrder.status = res.shopOrdersReject.order.status;
      cloneOrder.rejectionComment = res.shopOrdersReject.order.rejectionComment;
      cloneOrder.availableGoods = res.shopOrdersReject.order.availableGoods;
      cloneOrder.unavailableGoods = res.shopOrdersReject.order.unavailableGoods;

      setOrder(cloneOrder);

      onToggleModal("rejectApplication");
    },
    onError: error => {
      setMessageError(error)
      onToggleModal('error');
    }
  });

  const [orderUpdate, { loading: loadingOrderUpdate }] = useMutation(SHOP_ORDERS_UPDATE, {
    onCompleted: data => {
      const cloneOrder = cloneDeep(order);

      cloneOrder.manager = data.shopOrdersUpdate.order.manager;
      cloneOrder.entity = data.shopOrdersUpdate.order.entity;

      setOrder(cloneOrder);
    },
    onError: error => {
      setMessageError(error)
      onToggleModal('error');
    }
  });

  const [activeTab, setActiveTab] = useState(0);
  const onChangeTab = event => {
    setActiveTab(event);
  };

  const [showModalAddBilling, setShowModalAddBilling] = useState(false);

  useEffect(() => {
    if (showModalAddBilling) {
      getInvoiceValues({
        variables: {
          targetId: idOrder,
          targetType: "SHOP_ORDER"
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalAddBilling]);

  useEffect(() => {
    if (dataInvoiceDefaultValues?.invoiceDefaultValues?.number) {
      const cloneParamsInvoices = cloneDeep(paramsInvoices);
      cloneParamsInvoices.number = dataInvoiceDefaultValues?.invoiceDefaultValues?.number || '';
      setParamsInvoices(cloneParamsInvoices)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInvoiceDefaultValues])

  const [showModalAddGood , setShowModalAddGood] = useState(false);

  const [goods, setGoods] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const LIMIT = 20;

  const [fetchShopGoods, {
    data: dataShopGoods,
    error: errorShopGoods,
    loading: loadingShopGoods,
  }] = useLazyQuery(GET_SHOP_GOODS, {
    fetchPolicy: 'network-only'
  });

  const [openAutocompleteGoods, setOpenAutocompleteAutocompleteGoods] = useState(false);

  const handleOpenAutocompleteGoods = useCallback(() => setOpenAutocompleteAutocompleteGoods(true), []);
  const handleCloseAutocompleteGoods = useCallback(() => {
    setOpenAutocompleteAutocompleteGoods(false);

    setGoods([]);
    setSearchTerm('');
    setCurrentPage(1);

    fetchShopGoods({
      variables: {
        page: currentPage,
        limit: LIMIT,
        sort: {
          field: "createdAt",
          order: "DESC",
        },
        filter: {
          statusEq: "PUBLISHED",
          nameCont: searchTerm,
        }
      }
    });
  }, []);

  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const handleScrollList = useCallback(({ target }) => {
    const { clientHeight, scrollHeight, scrollTop } = target;
    setIsScrollBottom(
      Math.round(scrollHeight - scrollTop) - 25 <= clientHeight,
    );
  }, []);

  const debouncedSearchAutocompleteGoods = useDebouncedCallback((value) => {
    setSearchTerm(value);
    setGoods([]);
    setCurrentPage(1);
  }, 400);

  const handleSearchAutocompleteGoods = useCallback(({ target: { value } }) => {
    if (value?.length < 2) return false

    debouncedSearchAutocompleteGoods.callback(value);
  }, [debouncedSearchAutocompleteGoods]);

  useEffect(() => {
    if (isScrollBottom) {
      setCurrentPage((prev) => prev < totalPages ? prev + 1 : prev);
    }
  }, [isScrollBottom, totalPages]);

  useEffect(() => {
    if (showModalAddGood) {
      fetchShopGoods({
        variables: {
          page: currentPage,
          limit: LIMIT,
          sort: {
            field: "createdAt",
            order: "DESC",
          },
          filter: {
            statusEq: "PUBLISHED",
            nameCont: searchTerm,
          }
        }
      });
    }
  }, [showModalAddGood, fetchShopGoods, currentPage, searchTerm]);

  useEffect(() => {
    if (dataShopGoods) {
      const { shopGoods } = dataShopGoods;

      setGoods((prev) => prev.concat(shopGoods.goods.collection));
      setTotalPages(shopGoods.goods.metadata.totalPages)
    }
  }, [dataShopGoods]);

  const [addGood, { loading: loadingAddGood  }] = useMutation(SHOP_ORDERS_ADD_GOOD, {
    onCompleted: data => {
      const cloneOrder = cloneDeep(order);

      cloneOrder.availableGoods = data.shopOrdersAddGood.order.availableGoods;
      cloneOrder.amount = data.shopOrdersAddGood.order.amount;
      cloneOrder.amountWithDiscount = data.shopOrdersAddGood.order.amountWithDiscount;
      cloneOrder.goodsCount = data.shopOrdersAddGood.order.goodsCount;

      setOrder(cloneOrder);

      setSelectGood('NONE');
      onToggleModal('addGood');
    },
    onError: error => {
      setMessageError(error)
      onToggleModal('addGood');
      onToggleModal('error');
    }
  });

  const [removeGood, { loading: loadingRemoveGood  }] = useMutation(SHOP_ORDERS_REMOVE_GOOD, {
    onCompleted: data => {
      const cloneOrder = cloneDeep(order);

      cloneOrder.availableGoods = data.shopOrdersRemoveGood.order.availableGoods;
      cloneOrder.unavailableGoods = data.shopOrdersRemoveGood.order.unavailableGoods;
      cloneOrder.amount = data.shopOrdersRemoveGood.order.amount;
      cloneOrder.amountWithDiscount = data.shopOrdersRemoveGood.order.amountWithDiscount;
      cloneOrder.goodsCount = data.shopOrdersRemoveGood.order.goodsCount;

      setOrder(cloneOrder);

      setIdGoodsForRemove(null);
      onToggleModal('removeGood');
    },
    onError: error => {
      setMessageError(error)
      onToggleModal('removeGood');
      onToggleModal('error');
    }
  });

  const [invoicesCreate, { loading: loadingInvoicesCreate }] = useMutation(INVOICES_CREATE, {
    onCompleted: res => {
      const cloneOrder = cloneDeep(order);

      cloneOrder.application.status = "DEAL";

      cloneOrder.invoices.push(res.invoicesCreate.invoice);

      if (cloneOrder.status === "PENDING") {
        cloneOrder.status = "WAITING_FOR_PAYMENT";
      }

      setOrder(cloneOrder);

      onCloseModalAddBilling()
    },
    onError: error => {
      setMessageError(error)
      onToggleModal('error');
    }
  });

  const [suppliesCreate, { loading: loadingSuppliesCreate }] = useMutation(SHOP_SUPPLIES_CREATE, {
    onCompleted: res => {
      const cloneOrder = cloneDeep(order);
      cloneOrder.supplies.push(res.shopSuppliesCreate.supply);
      setOrder(cloneOrder);

      onCloseModalAddShipments();
    },
    onError: error => {
      setMessageError(error)
      onToggleModal('error');
    }
  });

  const [changeStatusOrder, { loading: loadingChangeStatusOrder }] = useMutation(SHOP_ORDERS_CHANGE_STATUS, {
    onCompleted: res => {
      const cloneOrder = cloneDeep(order);
      cloneOrder.status = res.shopOrdersChangeStatus.order.status;
      cloneOrder.invoices = res.shopOrdersChangeStatus.order.invoices;
      cloneOrder.availableGoods = res.shopOrdersChangeStatus.order.availableGoods;
      setOrder(cloneOrder);
    },
    onError: error => {
      setMessageError(error)
      onToggleModal('error');
    }
  });

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onToggleModal = modal => {
    switch (modal) {
      case 'rejectApplication':
        setShowModalRejectApplication(!showModalRejectApplication);
        break;

      case 'addGood':
        setShowModalAddGood(!showModalAddGood);
        break;

      case 'addNotes':
        setShowModalAddNotes(!showModalAddNotes);
        break;

      case 'manager':
        setShowModalManager(!showModalManager);

        if (!showModalManager) {
          setManager(null);
        }

        break;

      case 'addCompany':
        setShowModalAddCompany(!showModalAddCompany);

        if (!showModalAddCompany) {
          setSupplier(null)
        }

        break;

      case 'addShipments':
        setShowModalAddShipments(!showModalAddShipments);
        break;

      case 'removeGood':
        setShowModalRemoveGood(!showModalRemoveGood);
        break;

      case 'addBilling':
        setShowModalAddBilling(!showModalAddBilling);

        break;

      case 'error':
        setShowModalError(!showModalError);

        break;

      default:
        setShowModalRejectApplication(false);
        setShowModalAddGood(false);
        setShowModalAddNotes(false);
        setShowModalManager(false);
        setShowModalAddCompany(false);
        setShowModalAddShipments(false);
        setShowModalRemoveGood(false);
        setShowModalAddBilling(false);
        setShowModalError(false);
    }
  };

  const [showModalAddShipments  , setShowModalAddShipments] = useState(false);

  const INIT_PARAMS_CREATE_SHIPMENTS = {
    amount: null,
    deliveredAt: null,
    descriptionFileShipping: null,
    descriptionFileOther: null
  }

  const [paramsCreateShipments, setParamsCreateShipments] = useState({
    ...cloneDeep(INIT_PARAMS_CREATE_SHIPMENTS)
  });

  const onChangeFieldShipments = (type, event) => {
    const cloneParamsCreateShipments = cloneDeep(paramsCreateShipments);

    if (type === 'deliveredAt') {
      cloneParamsCreateShipments[type] = dayjs(event).utc(true).startOf("day").format(DATE_TIME_FORMAT_NOT_Z);
    } else {
      cloneParamsCreateShipments[type] = event.target.value;
    }

    setValue(type, cloneParamsCreateShipments[type]);

    setParamsCreateShipments(cloneParamsCreateShipments);

    clearErrors(type)
  }

  const onCreateShipments = () => {
    const { amount, deliveredAt, descriptionFileOther, descriptionFileShipping } = paramsCreateShipments;

    let arrFilesOther = []

    filesOther.forEach(file => {
      arrFilesOther.push({
        asset: file,
        explanation: descriptionFileOther
      })
    })

    let arrFilesShipping = []

    filesShipping.forEach(file => {
      arrFilesShipping.push({
        asset: file,
        explanation: descriptionFileShipping
      })
    })

    suppliesCreate({
      variables: {
        params: {
          orderId: Number(idOrder),
          amount: Number(amount),
          deliveredAt,
          shopSupplyMiscs: arrFilesOther,
          shopSupplyUpds: arrFilesShipping
        }
      }
    })
  }

  const [filesOther, setFilesOther] = useState([]);
  const [filesShipping, setFilesShipping] = useState([]);

  useEffect(() => {
    if (filesShipping?.length > 0) {
      setValue('filesShipping', filesShipping)

      clearErrors('filesShipping')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesShipping])

  const onCloseModalAddShipments = () => {
    onToggleModal();

    setFilesShipping([]);
    setFilesOther([]);

    setParamsCreateShipments({
      ...cloneDeep(INIT_PARAMS_CREATE_SHIPMENTS)
    });
  }

  const renderModalAddShipments = () => {
    const { deliveredAt, descriptionFileOther, descriptionFileShipping } = paramsCreateShipments;

    return (
      <Dialog fullWidth open={showModalAddShipments} scroll="body" sx={ styles.modal }>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Новая поставка</Typography>

          <IconButton onClick={onCloseModalAddShipments}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px' }}>
          <Box sx={{ display: 'flex', gap: '16px', paddingBottom: '25px' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={ styles.label }>
                Сумма:
                {" "}<span style={{ color: "red" }}>*</span>
              </Typography>

              <Controller
                control={control}
                name="amount"
                render={(props) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ zIndex: 1 }}>
                          <Box alt={'Рублей'} component={"img"} src={minRub} />
                        </InputAdornment>
                      ),
                    }}
                    error={errors?.amount}
                    fullWidth
                    helperText={errors?.amount?.message}
                    onChange={onChangeFieldShipments.bind(this, 'amount')}
                    placeholder={'0.00'}
                    sx={{
                      ...styles.input,
                      '& fieldset': {
                        background: '#ffffff',
                        borderRadius: '8px',
                      }
                    }}
                    type="number"
                    // eslint-disable-next-line react/prop-types
                    value={props.value}
                  />
                )}
                rules={{
                  required: "Данное поле обязательное"
                }}
              />
            </Box>

            <Box sx={{ width: '50%' }}>
              <Typography sx={ styles.label }>
                Дата:
                {" "}<span style={{ color: "red" }}>*</span>
              </Typography>

              <Controller
                control={control}
                name="deliveredAt"
                render={(props) => (
                  <DatePickerBox
                    hasError={errors?.deliveredAt}
                    helperText={errors?.deliveredAt?.message}
                    onChange={onChangeFieldShipments.bind(this, 'deliveredAt')}
                    placeholder={"15.02.2020"}
                    size="small"
                    sx={ styles.inputDate }
                    // eslint-disable-next-line react/prop-types
                    value={deliveredAt}
                  />
                )}
                rules={{
                  required: "Данное поле обязательное"
                }}
              />

              {errors?.deliveredAt?.message && (
                <FormHelperText error={errors?.deliveredAt?.message}>
                  {errors?.deliveredAt?.message}
                </FormHelperText>
              )}
            </Box>
          </Box>

          <Box sx={{ paddingBottom: '25px' }}>
            <Typography sx={ styles.label }>
              Документы отгрузки:
              {" "}<span style={{ color: "red" }}>*</span>
            </Typography>

            <Controller
              control={control}
              name="filesShipping"
              render={(props) => (
                <AzDropzone
                  canGlobDrag={false}
                  onFilesDropped={setFilesShipping}
                />
              )}
              rules={{
                required: "Данное поле обязательное"
              }}
            />

            {errors?.filesShipping?.message && (
              <FormHelperText error={errors?.filesShipping?.message}>
                {errors?.filesShipping?.message}
              </FormHelperText>
            )}
          </Box>

          {filesShipping?.length > 0 && (
            <Box sx={{ marginBottom: '20px' }}>
              {filesShipping.map(file => (
                <Typography key={file.id}>{file.path}</Typography>
              ))}
            </Box>
          )}

          <Box sx={{ paddingBottom: '25px' }}>
            <Typography sx={ styles.label }>
              Описание:
            </Typography>

            <TextField
              disabled={!filesShipping.length}
              fullWidth
              minRows={2}
              multiline={true}
              onChange={onChangeFieldShipments.bind(this, 'descriptionFileShipping')}
              placeholder={'Описание к документу'}
              sx={{ ...styles.input, maxHeight: 'auto' }}
              type="text"
              value={descriptionFileShipping}
            />
          </Box>

          <Box sx={{ paddingBottom: '25px' }}>
            <Typography sx={ styles.label }>
              Прочие документы:
            </Typography>

            <AzDropzone canGlobDrag={false} onFilesDropped={setFilesOther} />
          </Box>

          {filesOther?.length > 0 && (
            <Box sx={{ marginBottom: '20px' }}>
              {filesOther.map(file => (
                <Typography key={file.id}>{file.path}</Typography>
              ))}
            </Box>
          )}

          <Box>
            <Typography sx={ styles.label }>
              Описание:
            </Typography>

            <TextField
              disabled={!filesOther.length}
              fullWidth
              minRows={2}
              multiline={true}
              onChange={onChangeFieldShipments.bind(this, 'descriptionFileOther')}
              placeholder={'Описание к документу'}
              sx={{ ...styles.input, maxHeight: 'auto' }}
              type="text"
              value={descriptionFileOther}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: '24px' }}>
          <Button onClick={onCloseModalAddShipments} sx={ styles.grayButton }>
            Отмена
          </Button>

          <Button
            disabled={loadingSuppliesCreate}
            onClick={handleSubmit(onCreateShipments)}
            sx={ styles.blueButton }
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  const [showModalRemoveGood, setShowModalRemoveGood] = useState(false);

  const onRemoveGood = () => {
    removeGood({
      variables: {
        goodId: idGoodsForRemove,
        orderId: idOrder
      }
    })
  }

  const renderModalRemoveGood = () => {
    const onCloseModal = () => {
      setIdGoodsForRemove(null);
      onToggleModal()
    }

    return (
      <Dialog fullWidth open={showModalRemoveGood} scroll="body" sx={ styles.modal }>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Удаление товара</Typography>

          <IconButton onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px' }}>
          <Typography>Вы уверены?</Typography>
        </DialogContent>

        <DialogActions sx={{ padding: '24px' }}>
          <Button onClick={onRemoveGood} sx={ styles.redButton }>
            Да
          </Button>
          <Button onClick={onCloseModal} sx={ styles.grayButton }>
            Нет
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const INIT_PARAMS_INVOICE = {
    cashless: false,
    comment: '',
    expirationDate: null,
    customerType: null,
  }

  const [paramsInvoices, setParamsInvoices] = useState({
      number: '',
      ...cloneDeep(INIT_PARAMS_INVOICE),
  });

  const onChangeFieldInvoices = (type, event) => {

    const cloneParamsInvoicesCreate = cloneDeep(paramsInvoices);

    if (type === 'expirationDate') {
      cloneParamsInvoicesCreate[type] = dayjs(event).utc(true).startOf("day").format(DATE_TIME_FORMAT_NOT_Z);
    } else if (type === 'cashless') {
      cloneParamsInvoicesCreate[type] = event.target.checked;
    } else {
      cloneParamsInvoicesCreate[type] = event.target.value;
    }

    setValue(type, cloneParamsInvoicesCreate[type]);

    setParamsInvoices(cloneParamsInvoicesCreate);

    clearErrors(type)
  }

  const onCreateInvoices = () => {
    const { cashless, comment, customerType, expirationDate, number } = paramsInvoices;

    invoicesCreate({
      variables: {
        targetType: "SHOP_ORDER",
        targetId: idOrder,
        params: {
          cashless,
          comment,
          expirationDate,
          number,
          customerType
        }
      }
    })
  }

  const onCloseModalAddBilling = () => {
    let cloneParamsInvoices = cloneDeep(paramsInvoices);

    cloneParamsInvoices = {
      ...cloneParamsInvoices,
      ...cloneDeep(INIT_PARAMS_INVOICE),
    }

    setParamsInvoices(cloneParamsInvoices)

    onToggleModal()
  }

  const renderModalAddBilling = () => {
    const { expirationDate, number } = paramsInvoices;

    if (number) {
      setValue('number', number);
    }

    return (
      <Dialog fullWidth open={showModalAddBilling} scroll="body" sx={{ ...styles.modal, '& .MuiDialog-paper': { maxWidth: '1041px' } }}>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Параметры генерации счета-договора</Typography>

          <IconButton onClick={onCloseModalAddBilling}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px' }}>
          <Box sx={{ marginBottom: '18px' }}>
            <Typography sx={ styles.label }>
              Имя (номер):
              {" "}<span style={{ color: "red" }}>*</span>
            </Typography>

            <Controller
              control={control}
              name="number"
              render={(props) => (
                <TextField
                  disabled={loadingInvoiceDefaultValues}
                  error={errors?.number}
                  fullWidth
                  helperText={errors?.number?.message}
                  onChange={onChangeFieldInvoices.bind(this, 'number')}
                  placeholder={'Договор-счет №5008 от 07.06.2023'}
                  sx={{ ...styles.input, maxHeight: 'auto' }}
                  type="text"
                  // eslint-disable-next-line react/prop-types
                  value={number}
                />
              )}
              rules={{
                required: "Данное поле обязательное"
              }}
            />

            {loadingInvoiceDefaultValues && (
              <Box sx={{ marginTop: "4px", display: "flex", alignItems: "center", gap: "6px" }}>
                <CircularProgress size={10} />
                <Typography sx={{ fontWeight: 300, fontSize: "12px" }}>Загружаем номер договора-счета</Typography>
              </Box>
            )}

            {errorInvoiceDefaultValues && (
              <Typography sx={{ fontWeight: 300, fontSize: "12px", marginTop: "4px" }}>При загрузке номера счета-договора возникла ошибка. Укажите вручную.</Typography>
            )}
          </Box>

          <Box sx={{ marginBottom: '18px' }}>
            <Typography sx={ styles.label }>
              Покупатель:
              {" "}<span style={{ color: "red" }}>*</span>
            </Typography>

            <Controller
              control={control}
              name="customerType"
              render={(props) => (
                <RadioGroup
                  onChange={onChangeFieldInvoices.bind(this, 'customerType')}
                  sx={{ display: "flex", flexDirection: "row" }}
                  // eslint-disable-next-line react/prop-types
                  value={props.value}
                >
                  <FormControlLabel
                    control={<Radio />}
                    disabled={!order?.entity}
                    label="Компания"
                    value="ENTITY"
                  />

                  <FormControlLabel
                    control={<Radio />}
                    label="Менеджер"
                    value="MANAGER"
                  />

                  <FormControlLabel
                    control={<Radio />}
                    label="Контакт"
                    value="CONTACT"
                  />
                </RadioGroup>
              )}
              rules={{
                required: "Данное поле обязательное"
              }}
            />

            {errors?.customerType?.message && (
              <FormHelperText error={errors?.customerType?.message}>
                {errors?.customerType?.message}
              </FormHelperText>
            )}
          </Box>

          <Box sx={{ marginBottom: '18px', width: '35%' }}>
            <Typography sx={ styles.label }>
              Срок оплаты:
              {" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>

            <Controller
              control={control}
              name="expirationDate"
              render={(props) => (
                <DatePickerBox
                  hasError={errors.expirationDate}
                  helperText={errors?.expirationDate?.message}
                  onChange={onChangeFieldInvoices.bind(this, 'expirationDate')}
                  placeholder={"15.02.2020"}
                  size="small"
                  sx={ styles.inputDate }
                  // eslint-disable-next-line react/prop-types
                  value={expirationDate}
                />
              )}
              rules={{
                required: "Данное поле обязательное"
              }}
            />

            {errors?.expirationDate?.message && (
              <FormHelperText error={errors?.expirationDate?.message}>
                {errors?.expirationDate?.message}
              </FormHelperText>
            )}
          </Box>

          <Typography sx={ styles.label }>
            Товары
          </Typography>

          <Box sx={{ ...styles.wrapperTable, marginTop: '23px'}}>
            <Grid
              columns={COLUMNS_MODEL_CREATOR_SKU_BILLING}
              getRowId={(row) => row.id}
              rootComponent={GridRoot}
              rows={order.availableGoods}
            >
              <RowDetailState />

              <VirtualTable
                cellComponent={(props) => (
                  <VirtualTable.Cell className="OrdersTableCell" {...props} />
                )}
                columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_SKU_BILLING}
                messages={{ noData: 'Нет данных' }}
              />

              <TableHeaderRow />
            </Grid>

            <Box sx={{ color: '#6E7884', fontSize: '16px', fontWeight: 300, padding: '16px', textAlign: 'right' }}>
              Итого:
              {' '}
              <Typography sx={{ color: '#0D1D32', fontSize: '20px', fontWeight: 700, display: 'inline-flex' }}>
                <CurrencyFormatter value={totalAmountGoods} />
              </Typography>
              {' '}
              В том числе НДС:
              {' '}
              <Typography sx={{ color: '#0D1D32', fontSize: '20px', fontWeight: 300, display: 'inline-flex' }}>
                <CurrencyFormatter value={totalVatGoods} />
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography sx={ styles.label }>
              Комментарий:
            </Typography>

            <Controller
              control={control}
              name="comment"
              render={(props) => (
                <TextField
                  fullWidth
                  minRows={3}
                  multiline={true}
                  onChange={onChangeFieldInvoices.bind(this, 'comment')}
                  placeholder={'Описание'}
                  sx={{ ...styles.input, maxHeight: 'auto' }}
                  type="text"
                />
              )}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: '24px', justifyContent: 'space-between' }}>
          <Controller
            control={control}
            name="cashless"
            render={(props) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={onChangeFieldInvoices.bind(this, 'cashless')}
                  />
                }
                label="Оплата за наличные"
                sx={styles.chekedInput}
              />
            )}
          />

          <Box>
            <Button
              disabled={loadingInvoicesCreate || loadingInvoiceDefaultValues}
              onClick={handleSubmit(onCreateInvoices)}
              sx={ styles.blueButton }
            >
              Создать
            </Button>

            <Button onClick={onCloseModalAddBilling} sx={{ ...styles.grayButton, marginLeft: '8px'}}>
              Закрыть
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }

  const [showModalError, setShowModalError] = useState(false);

  const renderModalError = () => {
    return (
      <Dialog fullWidth open={showModalError} scroll="body" sx={ styles.modal }>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Ошибка</Typography>

          <IconButton onClick={onToggleModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px' }}>
          Произошла ошибка.
          <br />
          {messageError && (
            <ErrorAlert message={messageError.message} />
          )}
        </DialogContent>

        <DialogActions sx={{ padding: '24px' }}>
          <Button onClick={onToggleModal} sx={ styles.grayButton }>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  const [showModalRejectApplication , setShowModalRejectApplication] = useState(false);

  const [commentReject, setCommentReject] = useState(null);

  const onOrderToReject = () => {
    orderToReject({
      variables: {
        rejectionComment: commentReject,
        orderId: idOrder
      }
    })
  }

  const renderModalRejectApplication = () => {
    return (
      <Dialog fullWidth open={showModalRejectApplication} scroll="body" sx={ styles.modal }>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Причина отклонения заявки</Typography>

          <IconButton onClick={onToggleModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px' }}>
          <Typography sx={ styles.label }>
            Комментарий:
          </Typography>

          <TextField
            fullWidth
            minRows={3}
            multiline={true}
            onChange={val => {
              setCommentReject(val.target.value)
            }}
            placeholder={'Описание'}
            sx={{ ...styles.input, maxHeight: 'auto' }}
            type="text"
          />
        </DialogContent>

        <DialogActions sx={{ padding: '24px' }}>
          <Button disabled={!commentReject?.length || loadingOrderToReject } onClick={onOrderToReject} sx={ styles.blueButton }>
            Отправить
          </Button>
          <Button onClick={onToggleModal} sx={ styles.grayButton }>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  const [selectGood, setSelectGood] = useState('NONE');

  const handleKitChange = value => {
    setSelectGood(value.id);
  }

  const onAddGood = () => {
    addGood({
      variables: {
        goodId: selectGood,
        orderId: idOrder
      }
    })
  }

  const renderModalAddGood = () => {
    return (
      <Dialog fullWidth open={showModalAddGood} scroll="body" sx={ styles.modal }>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Добавить товар</Typography>

          <IconButton onClick={onToggleModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px' }}>
          <Typography sx={ styles.label }>
            Товар:
          </Typography>

          <Controller
            control={control}
            name="goodId"
            render={({ onChange, ...props }) => (
              <Autocomplete
                ListboxProps={{ onScroll: handleScrollList }}
                autoComplete
                closeText="Скрыть список"
                disableClearable
                filterOptions={(options) => options}
                fullWidth
                getOptionLabel={(option) => option?.name}
                noOptionsText={loadingShopGoods ? 'Поиск...' : 'Нет данных'}
                onChange={(e, data) => {
                  handleKitChange(data)
                  onChange(data)
                }}
                onClose={handleCloseAutocompleteGoods}
                onInput={handleSearchAutocompleteGoods}
                onOpen={handleOpenAutocompleteGoods}
                open={openAutocompleteGoods}
                openText="Показать список"
                options={goods}
                {...props}
                renderInput={(params) => <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingShopGoods
                          ? <CircularProgress color="inherit" size={20} />
                          : null}
                      </>
                    ),
                  }}
                  disabled={loadingShopGoods}
                  helperText={errors?.shopGoodId?.message}
                  placeholder={"Выберите товар или начните писать его название (от 2-х символов)"}
                />}
                renderOption={(props,option) => {
                  return (
                    <li {...props} key={option.id} style={{'display': 'block'}}>
                      {option?.name} | Цена: <CurrencyFormatter value={option?.priceWithDiscount}/>
                    </li>
                  )
                }}
              />
            )}
          />

          {errorShopGoods && (
            <Typography sx={{marginTop: '10px'}}>При загрузке товаров возникла ошибка</Typography>
          )}
        </DialogContent>

        <DialogActions sx={{ padding: '24px' }}>
          <Button
            disabled={selectGood === 'NONE' || loadingAddGood}
            onClick={onAddGood}
            sx={ styles.blueButton }
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  const initNote = {
    body: null,
    priority: null
  }

  const [note, setNote] = useState({ ...initNote });

  const onChangeNote = (type, event, val) => {
    let value = null;

    if (type === 'body') {
      value = event.target.value
    } else {
      value = val;
    }

    const cloneNote = cloneDeep(note);
    cloneNote[type] = value;
    setNote(cloneNote)
  }

  const [showModalAddNotes , setShowModalAddNotes] = useState(false);

  const onSaveNotes = () => {
    const { body, priority} = note;

    createNote({
      variables: {
        body,
        priority,
        targetType: "SHOP_ORDER",
        targetId: idOrder
      }
    })
  }

  const renderModalAddNotes = () => {
    const { body, priority} = note;

    return (
      <Dialog fullWidth open={showModalAddNotes} scroll="body" sx={ styles.modal }>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Добавить заметку</Typography>

          <IconButton onClick={onToggleModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px' }}>
          <RadioGroup
            defaultValue="female"
            name="radio-buttons-group"
            onChange={onChangeNote.bind(this, 'priority')}
            sx={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}
          >
            <FormControlLabel
              control={<Radio />}
              label="Срочное"
              sx={styles.radioInput}
              value="URGENT"
            />
            <FormControlLabel
              control={<Radio />}
              label="Информация"
              sx={styles.radioInput}
              value="INFORM"
            />
            <FormControlLabel
              control={<Radio />}
              label="Действие"
              sx={styles.radioInput}
              value="ACTION"
            />
          </RadioGroup>

          <Typography sx={ styles.label }>
            Комментарий:
          </Typography>

          <TextField
            fullWidth
            minRows={3}
            multiline={true}
            onChange={onChangeNote.bind(this, 'body')}
            placeholder={'Описание'}
            sx={{ ...styles.input, maxHeight: 'auto' }}
            type="text"
          />
        </DialogContent>

        <DialogActions sx={{ padding: '24px' }}>
          <Button onClick={onToggleModal} sx={ styles.grayButton }>
            Отмена
          </Button>
          <Button disabled={!body?.length || !priority?.length} onClick={onSaveNotes} sx={ styles.blueButton }>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  const [showModalManager , setShowModalManager] = useState(false);

  const [manager, setManager] = useState(order.manager);

  const handleManagerChange = value => {
    setManager(value);
  }

  const onSaveManager = () => {
    orderUpdate({
      variables: {
        params: {
          managerId: Number(manager.id)
        },
        orderId: idOrder
      }
    });

    onToggleModal("manager");
  }

  const renderModalManager = () => {
    return (
      <Dialog fullWidth open={showModalManager} scroll="body" sx={ styles.modal }>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Менеджер</Typography>

          <IconButton onClick={onToggleModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px' }}>
          <Typography sx={ styles.label }>
            Выберите менеджера:
          </Typography>

          <AutocompleteTaskUsers
            onChange={handleManagerChange}
            sx={styles.select}
            value={manager}
          />
        </DialogContent>

        <DialogActions sx={{ padding: '24px' }}>
          <Button onClick={onToggleModal} sx={ styles.grayButton }>
            Отмена
          </Button>
          <Button disabled={loadingOrderUpdate || !manager} onClick={onSaveManager} sx={ styles.blueButton }>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  const [showModalAddCompany , setShowModalAddCompany] = useState(false);

  const [supplier, setSupplier] = useState(null);

  const handleSupplierChange = value => {
    setSupplier(value);
  }

  const onSaveEntities = () => {
    orderUpdate({
      variables: {
        params: {
          entityId: Number(supplier.id)
        },
        orderId: idOrder
      }
    })

    onToggleModal("addCompany");
  }

  const renderModalAddCompany = () => {
    return (
      <Dialog fullWidth open={showModalAddCompany} scroll="body" sx={ styles.modal }>
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>
            {order?.entity ? "Изменить компанию" : "Добавить компанию"}
          </Typography>

          <IconButton onClick={onToggleModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderTop: '1px solid #E7E8E9', paddingTop: '24px', paddingBottom: '24px', display: 'flex', gap: '24px' }}>
          <AutocompleteEntities
            label="Название компании или ИНН"
            onChange={handleSupplierChange}
            value={supplier}
          />

          <Link href={`${process.env.REACT_APP_API_HOST}/entities/new`} target={"_blank"}>
            <Button sx={{ ...styles.blueOutlineButton, minWidth: '180px', padding: '0 25px', height: '100%' }}>
                Новая компания
            </Button>
          </Link>
        </DialogContent>

        <DialogActions sx={{ padding: '24px' }}>
          <Button onClick={onToggleModal} sx={ styles.grayButton }>
            Отмена
          </Button>
          <Button disabled={loadingOrderUpdate || !supplier} onClick={onSaveEntities} sx={ styles.blueButton }>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  const [idGoodsForRemove, setIdGoodsForRemove] = useState(null);

  const handlerRemoveGood = id => {
    setIdGoodsForRemove(id);
    onToggleModal('removeGood');
  }

  const renderKits = (good, showOrders, showDispute) => {
    return (
      <Box sx={ styles.kitWrapper }>
        <Box>
          <Box sx={styles.kitHeadTitle}>
            <Typography sx={ styles.kitTitle }>{good.name}</Typography>

            <Box sx={styles.kitStatus}>
              <FormattedMessage id={`goods.status.${good.status}`} />
            </Box>
          </Box>

          <Box>
            ID: {good.id}
          </Box>

          {showOrders && (
            <Box>
              Заявки:{" "}

              {good?.orders?.map(order => <Link component={RouterLink} key={order.id} sx={{ marginRight: '6px' }} to={`/workspaces/shop/orders/details/${order.id}`}>{order.id}</Link>)}
            </Box>
          )}

          {showDispute && (
            <Box>
              № изъятия:{" "}

              <Link href={good?.storageAcceptance?.displayDisputeUrl}>{good?.storageAcceptance?.displayDisputeNumber}</Link>
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '8px' }}>
            <Typography sx={ styles.kitPrice }>
              <CurrencyFormatter value={good.priceWithDiscount} />
            </Typography>
            <Typography sx={ styles.kitVat }>Скидка: {good.discount || 0}%</Typography>
            <Typography sx={ styles.kitVat }>НДС <CurrencyFormatter value={good.vat} /></Typography>
          </Box>

          <Box sx={{ paddingTop: "10px" }}>
            {good?.currentLocation === "CLIENT" && (good?.currentLocationAddress || '---')}
            {good?.currentLocation === "STORAGE" && (good?.storage?.address || '---')}
          </Box>
        </Box>

        <Box>
          <IconButton disabled={loadingRemoveGood || DISABLED_BUTTONS_FROM_STATUS_MODEL["goods"]} onClick={handlerRemoveGood.bind(this, good.id)} sx={ styles.kitButton }>
            <SvgIcon component={IconBasket} inheritViewBox />
          </IconButton>
        </Box>
      </Box>
    )
  }

  const renderNotes = (notes, type) => {
    const templateNote = note => {
      return (
        <Box key={note.id} sx={{ padding: '24px',  borderBottom: '1px solid #E7E8E9', display: "flex", gap: "16px" }}>
          <AvatarUser
            avatar={note?.author?.avatar}
            firstName={note?.author?.contact?.firstName}
            lastName={note?.author?.contact?.lastName}
            size={64}
          />

          <Box sx={{ width: 'calc(100% - 64px)' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{note.author.displayName}</Typography>

            {note.author.roles.map(role => (
              <Typography key={role} sx={{ fontSize: '16px', fontWeight: 500, color: '#6E7884', marginBottom: '12px' }}>
                <FormattedMessage id={`user.role.${role}`} />
              </Typography>
            ))}

            <ReactSpoiler
              noOfLines={5}
              showLessComponent={<Typography sx={{ fontSize: '16px', fontWeight: 300, color: '#3D6CE7', display: 'inline' }}>Скрыть</Typography>}
              showMoreComponent={<Typography sx={{ fontSize: '16px', fontWeight: 300, color: '#3D6CE7', display: 'inline' }}>Развернуть</Typography>}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 300 }}>{note.body}</Typography>
            </ReactSpoiler>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '12px'  }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 300, color: '#6E7884'}}>
                {dayjs(note.createdAt).utc(true).format("DD.MM.YYYY / HH:mm")}
              </Typography>

              <Typography sx={{ fontWeight: '400', fontSize: '14px', color: '#6E7884', background: '#F5F6F7', borderRadius: '4px', padding: '4px 9px', maxHeight: '25px', display: 'inline-flex', alignItems: 'center' }}>Заказ</Typography>
            </Box>
          </Box>
        </Box>
      )
    }

    const templateEmpty = () => {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '50px 0 26px' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 300, color: '#B6BBC2' }}>Заметки не добавлены</Typography>
        </Box>
      )
    }

    if (type) {
      if (!notes.filter(({ priority }) => priority === type)?.length) {
        return templateEmpty()
      }

      return notes.filter(({ priority }) => priority === type).map(note => templateNote(note));
    } else {
      if (!notes?.length) {
        return templateEmpty()
      }

      return notes.map(note => templateNote(note));
    }
  }

  const renderClientInformation = () => {
    const contact = (
      <Box sx={{ position: 'relative', minHeight: '80px' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
          {order?.contact?.firstName && (
            <>
              {order.contact.firstName}
              {" "}
            </>
          )}
          {order?.contact?.middleName && (
            <>
              {order.contact.middleName}
              {" "}
            </>
          )}
          {order?.contact?.lastName && (
            order.contact.lastName
          )}
        </Typography>

        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#6E7884', marginBottom: '8px' }}>Клиент</Typography>

        <Typography sx={{ fontSize: '12px', fontWeight: 500, background: 'rgba(13, 29, 50, 0.1)', borderRadius: '6px', display: 'inline-block', padding: '3px 8px', color: '#6E7884', position: 'absolute', top: '0', right: '0' }}>Заявитель</Typography>

        <Link href={`${process.env.REACT_APP_API_HOST}/contacts/${order.contact.id}/edit`}>
          <IconButton sx={{ ...styles.buttonEdit, top: 'unset', right: '0', bottom: '0'}}>
            <IconEdit />
          </IconButton>
        </Link>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ marginRight: '6px' }}>
            Email:{" "}

            <Link href={`mailto:${order.contact.email}`}>
              {order.contact.email}
            </Link>
          </Box>

          <Box sx={{ marginRight: '6px' }}>
            Тел:{" "}

            <Link href={`tel:${order.contact.phone}`}>
              <Phone phone={order.contact.phone} />
            </Link>
          </Box>

          <WazzupLink contactId={order.contact.id} sx={{ fontSize: "22px" }} />

          <Link href={`https://t.me/${order.contact.phone}`} sx={{ display: 'inline-flex' }}>
            <Box alt={'Telegram'} component={"img"}  src={telegramIcon} sx={{  marginLeft: '6px' }} />
          </Link>
        </Box>
      </Box>
    )

    if (order?.entity) {
      return (
       <>
         <Box sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #E7E8E9', padding: '23px 0 0', position: 'relative', }}>
           <Box>
             <Typography sx={{ fontSize: '22px', fontWeight: 700, '& span': { color: '#3D6CE7' } }}>Компания <Link href={`${process.env.REACT_APP_API_HOST}/entities/${order.entity.id}`} target={"_blank"}>{order.entity.legalNameWithShortEntityType}</Link></Typography>
             <Typography sx={{ fontSize: '14px', fontWeight: 300, marginTop: '8px', '& span': { fontWeight: 500, color: '#B6BBC2' } }}><span>ИНН:</span> {order.entity.tin || "---"}  <span>КПП:</span> {order.entity.trrc || "---"}  <span>ОГРН:</span> {order.entity.psrn || "---"}</Typography>
             <Typography sx={{ fontSize: '14px', fontWeight: 300, marginTop: '8px', '& span': { fontWeight: 500, color: '#B6BBC2' } }}>
               <span>Адрес:</span> {order.entity.addresses.map(({ fullAddress }) => (<>{fullAddress}; <br /></> ))}
             </Typography>
           </Box>

           <IconButton disabled={DISABLED_BUTTONS_FROM_STATUS_MODEL["entity"]} onClick={onToggleModal.bind(this, 'addCompany')} sx={ styles.buttonEdit}>
             <IconEdit />
           </IconButton>
         </Box>

         <Box sx={{ border: '1px solid #E7E8EA', borderRadius: '8px', marginTop: '24px', padding: '24px' }}>
           {contact}
         </Box>
       </>
      )
    };

    return contact;
  }

  const updateInvoice = invoice => {
    const cloneOrder = cloneDeep(order);
    let index = findIndex(cloneOrder.invoices, i => { return i.id === invoice.id });
    cloneOrder.invoices[index] = invoice;
    setOrder(cloneOrder)
  }

  const updateSupplies = supplier => {
    const cloneOrder = cloneDeep(order);
    let index = findIndex(cloneOrder.supplies, s => { return s.id === supplier.id });
    cloneOrder.supplies[index] = supplier;
    setOrder(cloneOrder);
  }

  const onOrderIsPaid = () => {
    changeStatusOrder({
      variables: {
        orderId: idOrder,
        transition: "MARK_AS_PAID"
      }
    })
  }

  const onOrderIsSell= () => {
    changeStatusOrder({
      variables: {
        orderId: idOrder,
        transition: "SELL"
      }
    })
  }

  const handlerDisabledAddInvoice = () => {
    let disabled = false;

    find(order.invoices, (invoice) => {
      if (invoice.status === "PENDING" || invoice.status === "PAID") {
        disabled = true;
      }
    })

    return disabled;
  }


  const DISABLED_BUTTONS_FROM_STATUS_MODEL  = {
    goods: (() => (order.status === "PAID" || order.status === "EXTINGUISHED" || order.status === "REJECTED"))(),
    entity: (() => (order.status === "PAID" || order.status === "EXTINGUISHED" || order.status === "REJECTED"))(),
    invoices: (() => (order.status === "PAID" || order.status === "EXTINGUISHED" || order.status === "REJECTED"))(),
    supplies: (() => (order.status === "PENDING" || order.status === "WAITING_FOR_PAYMENT" || order.status === "EXTINGUISHED" || order.status === "REJECTED"))(),
    buttonOrderPaid: (() => (order.status === "PENDING"))(),
  }

  if (loadingOrder) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column" }}>
        <CircularProgress />
        <Typography sx={{ fontSize: "20px" }}>Загружаем заявку</Typography>
      </Box>
    )
  }

  if (errorOrder) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column" }}>
        <ErrorAlert message={errorOrder.message} title={`При загрузке заявки №${idOrder} произошла ошибка.`} />
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ paddingBottom: '20px', height: '100%', overflow: 'auto', }}>
        <Box sx={{ padding: '0 24px', backgroundColor: '#ffffff', height: '103px', boxShadow: '0 9px 13px rgb(13 29 50 / 12%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
            <Link href={`${process.env.REACT_APP_CLOUD_HOST}/workspaces/shop/orders`} sx={ styles.returnOutlineButton }>
              <SvgIcon component={ReturnIcon} sx={{ height: '14px', width: '14px' }} viewBox="0 0 14 14" />
            </Link>

            <Box>
              <Typography gutterBottom sx={ styles.pageTitle } variant="h2">
                Заявка <span>№{order.id}</span>

                <Box className={'sale'} sx={ styles.chipTitle }>
                  <FormattedMessage id={`shop.order.status.${order.status}`}/>
                </Box>

                {order.status === "EXTINGUISHED" && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                    <ReportIcon sx={{ color: '#8e8e09' }} />
                    <Typography sx={{ color: '#212121' }}>Пожалуйста, перейдите в основную заявку и завершите её!</Typography>
                  </Box>
                )}

                {order.status === "REJECTED" && (
                  <Tooltip title={order.rejectionComment || "Комментарий отсутствует"}>
                    <ErrorOutlineIcon />
                  </Tooltip>
                )}

                <HelpTooltip title={(<a href={`${process.env.REACT_APP_HOST}/wiki_page/242`} rel="noreferrer" style={{color: '#fff', fontSize: '14px'}} target='_blank'>Перейти к описанию</a>)} />
              </Typography>

              <Box>
                Заявка <Link href={`${process.env.REACT_APP_HOST}/applications/${order.application.id}`}>{order.application.id}</Link>
                {" "}
                <Box className={order.application.status.toLowerCase()} component={"span"} sx={ styles.colorStatus }>
                  <FormattedMessage id={`application.substatus.${order.application.status}`} />
                </Box>
              </Box>
            </Box>
          </Box>

          <Stack direction="row" spacing={2}>
            <Button
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              disabled={order.status === "REJECTED" || order.status === "EXTINGUISHED"}
              id="composition-button"
              onClick={handleToggle}
              ref={anchorRef}
              sx={{
                background: '#3D6CE7',
                borderRadius: '6px',
                width: '137px',
                height: '48px',
                fontSize: '16px',
                fontWeight: 500,
                color: '#ffffff',
                textTransform: 'none',
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
                '&:hover': {
                  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #3D6CE7',
                  boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
                },
                '&:active': {
                  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #3D6CE7',
                  boxShadow: 'none',
                },
                '&:disabled': {
                  background: '#e8e8e8',
                  color: '#868E98',
                },
              }}
            >
              Действия

              {open === true ? (
                <SvgIcon component={ArrawDownIcon} sx={{ height: '7px', width: '12px', marginLeft: '14px', transform: 'rotate(180deg)' }} viewBox="0 0 12 7" />
              ) : (
                <SvgIcon component={ArrawDownIcon} sx={{ height: '7px', width: '12px', marginLeft: '14px' }} viewBox="0 0 12 7" />
              )}
            </Button>

            <Popper
              anchorEl={anchorRef.current}
              disablePortal
              open={open}
              placement="bottom-start"
              role={undefined}
              style={{ zIndex: 3 }}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        aria-labelledby="composition-button"
                        autoFocusItem={open}
                        id="composition-menu"
                        onKeyDown={handleListKeyDown}
                        sx={{
                          '& .MuiListItem-button': {
                            color: '#6E7884',
                            fontSize: '16px',
                            fontWeight: 500,
                          }
                        }}
                      >
                        {order.status === "PAID" ? (
                            <MenuItem disabled={loadingChangeStatusOrder || loadingOrderToReject} onClick={event => { handleClose(event); onOrderIsSell() }}>Завершить</MenuItem>
                        ) : (
                          <>
                            <MenuItem
                              disabled={loadingChangeStatusOrder || loadingOrderToReject || DISABLED_BUTTONS_FROM_STATUS_MODEL["buttonOrderPaid"]}
                              onClick={event => { handleClose(event); onOrderIsPaid() }}
                            >Счёт оплачен</MenuItem>
                            <MenuItem
                              disabled={loadingChangeStatusOrder || loadingOrderToReject}
                              onClick={event => { handleClose(event); onToggleModal.call(this, 'rejectApplication') }}
                            >Отклонить</MenuItem>
                          </>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>
        </Box>

        <Box sx={ styles.wrapperContent }>
          <Box sx={ styles.contentLeft }>
            <Box sx={ styles.content }>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '23px' }}>
                <Typography sx={{ fontSize: '22px', fontWeight: 700, marginBottom: '0', alignItems: 'center' }}>Товары</Typography>

                <Button disabled={DISABLED_BUTTONS_FROM_STATUS_MODEL["goods"]} onClick={onToggleModal.bind(this, 'addGood')} sx={styles.blueOutlineButton}>
                  Добавить товар
                  <AddIcon sx={{ marginLeft: '8px' }}/>
                </Button>
              </Box>

              {order?.availableGoods?.length ? (
                <>
                  <Box>
                    {order.availableGoods.map(good => renderKits(good, false, true))}
                  </Box>

                  <Box sx={ styles.kitTotalWrapper }>
                    Итого:
                    {" "}
                    <Typography sx={styles.kitTotalBold}>
                      <CurrencyFormatter value={totalAmountGoods} />
                    </Typography>
                    {" "}
                    <Typography sx={ styles.kitVal }>(НДС <CurrencyFormatter value={totalVatGoods} />)</Typography>
                    {" "}
                    Шт.: <Typography sx={styles.kitTotalBold}>{order.availableGoods?.length || 0}</Typography>
                  </Box>
                </>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', borderTop: '1px solid #E7E8E9', padding: '50px 0 26px' }}>
                  <Typography sx={{ fontSize: '20px', fontWeight: 300, color: '#B6BBC2' }}>Товары не добавлены</Typography>
                </Box>
              )}
            </Box>

            <Box sx={ styles.content }>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '23px' }}>
                <Typography sx={{ fontSize: '22px', fontWeight: 700, marginBottom: '0', alignItems: 'center' }}>Недоступно к заказу</Typography>
              </Box>

              {order?.unavailableGoods?.length ? (
                <Box>
                  {order.unavailableGoods.map(good => renderKits(good, true))}
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', borderTop: '1px solid #E7E8E9', padding: '50px 0 26px' }}>
                  <Typography sx={{ fontSize: '20px', fontWeight: 300, color: '#B6BBC2' }}>Пусто</Typography>
                </Box>
              )}
            </Box>

            <Box sx={ styles.content }>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '23px' }}>
                <Typography sx={{ fontSize: '22px', fontWeight: 700, marginBottom: '0', alignItems: 'center' }}>Информация о клиенте</Typography>

                <Button disabled={order.entity || DISABLED_BUTTONS_FROM_STATUS_MODEL["entity"]} onClick={onToggleModal.bind(this, 'addCompany')} sx={styles.blueOutlineButton}>
                  Добавить компанию
                  <AddIcon sx={{ marginLeft: '8px' }}/>
                </Button>
              </Box>

              {renderClientInformation()}
            </Box>

            <Box sx={ styles.content }>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '23px' }}>
                <Typography sx={{ fontSize: '22px', fontWeight: 700, marginBottom: '0', alignItems: 'center' }}>Счета</Typography>

                <Button
                  disabled={handlerDisabledAddInvoice() || DISABLED_BUTTONS_FROM_STATUS_MODEL["invoices"]}
                  onClick={onToggleModal.bind(this, 'addBilling')}
                  sx={styles.blueOutlineButton}
                >
                  Добавить счёт
                  <AddIcon sx={{ marginLeft: '8px' }}/>
                </Button>
              </Box>

              {order?.invoices?.length ? (
                <Box className={'insaid'} sx={ styles.wrapperTable }>
                  <Grid
                    columns={COLUMNS_MODEL_CREATOR_BILLS}
                    getRowId={(row) => row.id}
                    rootComponent={GridRoot}
                    rows={order.invoices}
                  >
                    <DocumentInvoiceNumberPDF
                      for={['status_number']}
                      setMessageError={setMessageError}
                      showErrors={onToggleModal.bind(this, 'error')}
                    />

                    <DocumentActionTypeProviderReject
                      disabledButton={DISABLED_BUTTONS_FROM_STATUS_MODEL["invoices"]}
                      for={['reject']}
                      setMessageError={setMessageError}
                      showErrors={onToggleModal.bind(this, 'error')}
                      updateInvoice={updateInvoice}
                    />

                    <RowDetailState />

                    <VirtualTable
                      cellComponent={(props) => (
                        <VirtualTable.Cell className="OrdersTableCell" {...props} />
                      )}
                      columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_BILLS}
                      messages={{ noData: 'Нет данных' }}
                    />
                    <TableHeaderRow />
                  </Grid>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', borderTop: '1px solid #E7E8E9', padding: '50px 0 26px' }}>
                  <Typography sx={{ fontSize: '20px', fontWeight: 300, color: '#B6BBC2' }}>Счета не добавлены</Typography>
                </Box>
              )}
            </Box>

            <Box sx={ styles.content }>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '23px' }}>
                <Typography sx={{ fontSize: '22px', fontWeight: 700, marginBottom: '0', alignItems: 'center' }}>Отгрузки</Typography>

                <Button
                  disabled={DISABLED_BUTTONS_FROM_STATUS_MODEL["supplies"]}
                  onClick={onToggleModal.bind(this, 'addShipments')}
                  sx={styles.blueOutlineButton}>
                  Добавить отгрузку
                  <AddIcon sx={{ marginLeft: '8px' }}/>
                </Button>
              </Box>

              {order?.supplies?.length ? (
                <>
                  <Box className={'insaid'} sx={ styles.wrapperTable }>
                    <Grid
                      columns={COLUMNS_MODEL_CREATOR_SHIPMENTS}
                      getRowId={(row) => row.id}
                      rootComponent={GridRoot}
                      rows={order.supplies}
                    >
                      <DocumentActionTypeProviderShipmentsUploadFile
                        for={['uploadFile']}
                        setMessageError={setMessageError}
                        showErrors={onToggleModal.bind(this, 'error')}
                        updateSupplies={updateSupplies}
                      />

                      <DocumentActionTypeProviderShippingDocument
                        for={['shippingDocument']}
                      />

                      <DocumentActionTypeProviderShipmentsActions
                        disabledButton={DISABLED_BUTTONS_FROM_STATUS_MODEL["supplies"]}
                        for={['actions']}
                        setMessageError={setMessageError}
                        showErrors={onToggleModal.bind(this, 'error')}
                        updateSupplies={updateSupplies}
                      />

                      <RowDetailState />

                      <VirtualTable
                        cellComponent={(props) => (
                          <VirtualTable.Cell className="OrdersTableCell" {...props} />
                        )}
                        columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_SHIPMENTS}
                        messages={{ noData: 'Нет данных' }}
                      />
                      <TableHeaderRow />
                    </Grid>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #EAEAEC', paddingTop: '27px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography sx={{ color: '#6E7884', fontSize: '16px', fontWeight: 300 }}>Всего:</Typography>
                      <Typography sx={{ color: '#0D1D32', fontSize: '20px', fontWeight: 700 }}>
                        <CurrencyFormatter value={totalAmountSupplies} />
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography sx={{ color: '#6E7884', fontSize: '16px', fontWeight: 300 }}>Остаток отгрузок:</Typography>
                      <Typography sx={{ color: '#FF5550', fontSize: '20px', fontWeight: 700 }}>
                        <CurrencyFormatter value={order.amountWithDiscount - totalAmountSupplies} />
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', borderTop: '1px solid #E7E8E9', padding: '50px 0 26px' }}>
                  <Typography sx={{ fontSize: '20px', fontWeight: 300, color: '#B6BBC2' }}>Отгрузки не добавлены</Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={ styles.contentRight }>
            <Box sx={{ ...styles.content, border: '1px solid #E7E8EA', boxShadow: 'none', display: 'flex', gap: '17px', position: 'relative'}}>
              <AvatarUser
                avatar={order?.manager?.avatar}
                firstName={order.manager?.contact?.firstName}
                lastName={order.manager?.contact?.lastName}
                size={60}
              />

              <Box>
                <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>Менеджер</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>{order.manager?.displayName}</Typography>

                <Box sx={{ fontSize: '14px', fontWeight: '500', display: 'flex' }}>
                  <Typography sx={{ marginRight: '6px' }}>
                    Email:
                    {" "}
                    {order.manager?.contact?.email ? (
                      <Link href={`mailto:${order.manager.contact.email}`}>
                        {order.manager.contact.email}
                      </Link>
                    ) : (
                      "---"
                    )}
                  </Typography>
                </Box>

                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Тел:
                  {" "}
                  {order.manager?.contact?.phone ? (
                    <Link href={`tel:${order.manager.contact.phone}`}>
                      <Phone phone={order.manager.contact.phone} />
                    </Link>
                  ) : (
                    "---"
                  )}
                </Typography>
              </Box>

              <IconButton onClick={onToggleModal.bind(this, 'manager')} sx={{ ...styles.buttonEdit, right: "24px"}}>
                <IconEdit />
              </IconButton>
            </Box>

            <Box sx={{ ...styles.content, padding: 0, border: '1px solid #E7E8EA', boxShadow: 'none' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '26px', fontWeight: 700, padding: '27px 24px' }}>Заметки</Typography>

                <IconButton onClick={onToggleModal.bind(this, 'addNotes')} sx={{ ...styles.greyOutlineButton, minWidth: 'unset', maxHeight: '48px', maxWidth: '48px'}}>
                  <AddIcon />
                </IconButton>
              </Box>

              <Tabs
                counterTabs={[
                  order.notes.length,
                  order.notes.filter(note => note.priority === "INFORM").length,
                  order.notes.filter(note => note.priority === "URGENT").length,
                  order.notes.filter(note => note.priority === "ACTION").length
                ]}
                defaultActiveTab={0}
                onChange={onChangeTab}
                sx={stylesTabs}
                tabs={[
                  'Все',
                  'Информация',
                  'Срочное',
                  'Действие',
                ]}
              />

              <Box>
                {loadingCreateNote ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "120px" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {activeTab === 0 && renderNotes(order.notes)}
                    {activeTab === 1 && renderNotes(order.notes, "INFORM")}
                    {activeTab === 2 && renderNotes(order.notes, "URGENT")}
                    {activeTab === 3 && renderNotes(order.notes, "ACTION")}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {showModalRejectApplication && renderModalRejectApplication()}
      {showModalAddGood && renderModalAddGood()}
      {showModalAddNotes && renderModalAddNotes()}
      {showModalManager && renderModalManager()}
      {showModalAddCompany && renderModalAddCompany()}
      {showModalAddShipments && renderModalAddShipments()}
      {showModalRemoveGood && renderModalRemoveGood()}
      {showModalAddBilling && renderModalAddBilling()}

      {showModalError && renderModalError()}
    </>
  );
}